@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
body {
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  background: linear-gradient(#045877, #000000);
  background-attachment: fixed;
}
h1,
h2,
p,
label,
input,
button,
a {
  margin: 0;
}
.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.input {
  width: 100%;
  border-radius: 5px;
  background: transparent;
  outline: none;
  border: 1px solid #fff;
  padding: 2px 5px;
  transition: all 0.5s ease-in-out;
}
textarea.input {
  min-height: 50px;
  max-height: 150px;
  overflow-y: auto;
}
.button {
  padding: 5px 20px;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #fff;
  outline: none;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.input:focus,
.button:hover {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.lead-activity-link {
  display: block;
  text-decoration: none;
}
.input::placeholder {
  color: #fff;
}
.logo {
  width: 50px;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.home-icon {
  font-size: 2rem;
}
.secondary-text {
  color: #afafaf;
}
